import React from "react"
import styled from "styled-components"

const Button = ({onClick, ...props}) => 
  <StyledButton onClick={onClick} props={props}>{props.children}</StyledButton>

const StyledButton = styled.button`
    position: relative;
    padding: 1.25rem;
    min-height: 46px;
    margin: 0;
    padding: 14px 35px;
    margin-top: 40px;
    vertical-align: baseline;
    border: 1px solid #000;
    outline: none; 
    background-color: #fff;
    cursor: pointer;
    border: 2px solid #000;
    display: inline-block;
    font-family: Work Sans, 'sans-serif';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 1.42px;
    line-height: 14px;
    text-align: center; 
    color: #000;
    text-transform: uppercase;    
    transition: all .3s ease-in-out;
    &:hover, &:focus {
      background-color: #000;
      color: #fff;
    }
`

export default Button
